<template>
  <div class="page page-xtrading-all-publications">
    <Side
      component="forms/publication/compact/FormPublicationCompact"
      :ids="(selected || []).map((v) => v.id)"
      :props="{ campaign_id: campaignId }"
      :value="showEdit"
      @close="closeEdit"
      @create="loadItems"
      @update="loadItems"
      @remove="removeItems"
    />
    <Side
      component="forms/job_publication/compact/FormJobPublicationCompact"
      :ids="(selected || []).map((v) => v.job_id)"
      :props="{ campaign_id: campaignId }"
      :value="showPublish"
      @close="closePublish"
    />
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :loading="loading"
      :selected="selected"
      :sortable="['created_at',  'updated_at']"
      :allSelectedItems="allSelectedItems"
      defaultSort="updatedat_desc"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @select="selectItems"
      @page="changePage"
      @click="selectItem"
      @dblclick="editItem"
      @reload="searchItems"
      @sort="sortBy"
      @unselect="allSelectedItems = false"
    >
      <template v-slot:header="">
        <v-col cols="3">
          <v-text-field
            ref="where.job.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-select
            ref="where.status_type"
            v-model="publicationStatusType"
            :items="$t('PUBLICATION.STATUS_TYPE_ITEMS')"
            :label="$t('PUBLICATION.STATUS')"
            item-text="text"
            item-value="value"
            multiple
            hide-details
            outlined
            dense
            @input="searchItems"
          />
        </v-col>
        <v-col cols="3" class="page-xtrading-all-publications__channels-list-wrap pl-0">
          <v-menu
            offset-y
            :nudge-top="40"
            :min-width="500"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-select
                v-bind="attrs"
                v-on="on"
                v-model="channelItemId"
                ref="where.channel_subscription_id"
                attach="#page-xtrading-all-publications__channels-select-menu"
                outlined
                dense
                hint
                clearable
                multiple
                :items="channelSubscriptionItems"
                :item-text="channelItem => channelItem.channel.title + (channelItem.alias ? ' (' + channelItem.alias + ')' : '') || 'channel.title'"
                :item-value="channelItem => channelItem.id || 'id'"
                :placeholder="$t('CHANNEL.TITLES')"
                :prepend-inner-icon="$t('CHANNEL.ICON')"
                @focus="channelsListVisible = true"
                @click:clear="selectChannel(item, true)"
              />
            </template>
            <v-virtual-scroll
              id="page-xtrading-all-publications__channels-select-menu"
              v-if="channelsListVisible"
              :bench="0"
              :height="304"
              :min-width="300"
              :item-height="50"
              :items="cloneChannelsItems"
              class="pt-2 pb-2"
            >
              <template v-slot:default="{ item }">
                <v-list>
                  <v-list-item
                    :id="item.id"
                    class="page-xtrading-all-publications__channels-item"
                    @click="selectChannel(item)"
                  >
                    <v-list-item-action>
                      <v-icon v-if="item.checked" class="theme--light primary--text">mdi-checkbox-marked</v-icon>
                      <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title :style="channelTitle" v-text="item.channel.title + (item.alias ? ' (' + item.alias + ')' : '')" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-virtual-scroll>
          </v-menu>
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-select
            ref="where.job.feed_id"
            :items="feedItems"
            item-text="title"
            item-value="id"
            hide-details
            outlined
            dense
            clearable
            multiple
            :prepend-inner-icon="$t('FEED.ICON')"
            :label="$t('FEED.TITLE')"
            @change="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
      </template>

      <template v-slot:filters="">
        <div class="pa-4">
          <v-row class="mt-0">
            <v-col>
              <v-checkbox
                ref="where.job.parent_id"
                :label="$t('JOB.PARENT')"
                :value="null"
                hide-details
                class="mt-0"
                @change="searchItems"
              />
            </v-col>
          </v-row>

          <v-divider class="mb-4 mt-4" />
          <p>{{$t('PUBLICATION.FIELD_PRICING_MODEL')}}</p>
          <v-row class="mt-0">
            <v-col>
              <v-select
                ref="where.method"
                :items="$t('PUBLICATION.METHOD_ITEMS')"
                hide-details
                rounded
                outlined
                dense
                clearable
                multiple
                :label="$t('PUBLICATION.FIELD_METHOD')"
                @change="searchItems"
                @click:clear="searchItems"
              />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="6">
              <v-text-field
                ref="where.price_min"
                rounded
                hide-details
                outlined
                dense
                clearable
                type="number"
                :min="0"
                :max="100"
                :step="0.01"
                :placeholder="$t('PUBLICATION.FIELD_PRICE') + ' min'"
                @keyup="searchItems"
                @click:clear="searchItems"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="where.price_max"
                rounded
                hide-details
                outlined
                dense
                clearable
                type="number"
                :min="0"
                :max="100"
                :step="0.01"
                :placeholder="$t('PUBLICATION.FIELD_PRICE') + ' max'"
                @keyup="searchItems"
                @click:clear="searchItems"
              />
            </v-col>
          </v-row>

        </div>
      </template>

      <template v-slot:nav="">
        <!-- <v-btn icon @click="searchItems"><v-icon>mdi-reload</v-icon></v-btn> -->
      </template>

      <template v-slot:empty="">
        <div class="ml-10 mt-6 text-center">
          <div class="text-h5 mt-10 mb-4">{{$t('LIST.NO_RESULT')}}</div>
          <template v-if="$queryFilters().count() > 0">
            <div><small class="grey--text">{{$t('LIST.SUGGESTION')}}</small></div>
            <div><a class="info--text" @click="clearFilters">{{$t('LIST.CLEAR_FILTER')}}</a></div>
          </template>
        </div>
      </template>

      <template v-slot:selections="">
        <v-col cols="12" class="pt-0 pl-0">
          <div><small><strong>{{$tc("COMMON.SELECTED", allSelectedItems ? countPublication : selected.length)}}</strong></small></div>

          <v-btn-toggle dark rounded>
            <v-btn
              small
              rounded
              depressed
              color="primary"
              @click="selectAllPages"
            >
              <v-icon size="20" class="mr-2">mdi-playlist-plus</v-icon>
              {{ $t("COMMON.SELECT_ALL_PAGES") }}
            </v-btn>

            <v-btn
              small
              rounded
              depressed
              color="primary"
              @click="showPublish = true"
            >
              <v-icon size="20" class="mr-2">mdi-forwardburger</v-icon>
              {{ $t("COMMON.PUBLISH") }}
            </v-btn>

            <v-btn
              small
              rounded
              depressed
              color="primary"
              @click="showEdit = true"
            >
              <v-icon size="20" class="mr-2">mdi-pencil</v-icon>
              {{ $t("COMMON.EDIT") }}
            </v-btn>

            <v-btn
              small
              rounded
              depressed
              color="error"
              @click="showDialogRemove = true"
            >
              <v-icon size="20" class="mr-2">mdi-delete</v-icon>
              {{ $t("COMMON.REMOVE") }}
            </v-btn>
            <v-btn
              small
              rounded
              depressed
              color="secondary custom black--text"
              @click="selectItems([])"
            >
              <v-icon size="20" class="mr-2" color="black">mdi-close</v-icon>
              {{ $t("COMMON.CANCEL") }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>

      <template v-slot:item-list="item">
        <div
          v-if="
            item.status_type &&
            (item.status_type === 'error' || item.status_type === 'info')
          "
          :class="['list-overlay', item.status_type ? item.status_type : '']"
        />

        <v-list-item-content>
          <v-list-item-title class="primary--text">
            <strong>{{ item.job.title }}</strong>
            <v-icon
              v-if="item.job.parent_id"
              :title="item.job.parent_id"
              color="grey"
              class="ml-2"
              size="14"
            >
              {{ $t("COMMON.DUPLICATE_ICON") }}
            </v-icon>
          </v-list-item-title>

          <v-list-item-subtitle>
            <span class="mr-2" v-if="item.job.company_title || item.job.company"
              ><v-icon class="mr-1" size="12">{{ $t("COMPANY.ICON") }}</v-icon
              >{{ item.job.company_title || item.job.company }}</span
            >
            <span class="mr-2" v-if="item.job.location"
              ><v-icon class="mr-1" size="12">mdi-map-marker</v-icon
              >{{
                item.job.location_city + ", " + item.job.location_city_code
              }}</span
            >
            <span class="mr-2" v-if="item.job.xtramile_sector_id"
              ><v-icon class="mr-1" size="12">mdi-bookmark</v-icon
              >{{ $t("JOB.SECTOR." + item.job.xtramile_sector_id) }}</span
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.status_message">
            <span :class="item.status_type + '--text'">{{item.status_message}}</span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="item.channel_subscription.ttl_config.enabled && item.commited_at">
          <status-label class="mr-3" small :item="{
            status_code: 'PUBLICATION.TTL_WARNING',
            status_code_suffix: $moment(item.commited_at).add(item.channel_subscription.ttl_config.ttl_in_days, 'days').diff($moment(new Date()), 'days', false) + ' jours'
          }"/>
        </v-list-item-action>

        <v-list-item-action>
          <status-label small :item="item" class="mr-3" />
        </v-list-item-action>

        <v-list-item-action class="pr-2 pl-2">
          <v-img contain width="70px" :src="getImage(item.channel)" />
        </v-list-item-action>
        <v-divider vertical />
        <v-list-item-action class="pl-2 pr-2 list-price">
          <div class="text-small text-uppercase">
            <small>{{ item.method }}</small>
          </div>
          <div v-if="item.method !== 'credit' && item.method !== 'organic'">
            <strong class="number">€ {{ (+item.price || 0).toFixed(2) }}</strong>
          </div>
          <div v-else-if="item.method === 'credit'">
            <strong class="number">Cr. {{ item.price }}</strong>
          </div>
          <div v-else>
            <strong class="number">-</strong>
          </div>
        </v-list-item-action>
        <v-divider vertical />

        <v-list-item-action class="ml-6 mr-3 date">
          {{ $moment(item.updated_at).fromNow() }}
        </v-list-item-action>

        <v-list-item-action>
          <div>
            <v-btn icon class="ml-2" @click.stop="removeItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon class="ml-2" @click.stop="editItem(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </template>
    </List>
  </div>
</template>

<style lang="scss">
#page-xtrading-all-publications__channels-select-menu{
  .v-menu__content {
    display: none !important;
  }
}

.list-price {
  width: 65px;
}

.page-xtrading-all-publications__channels-list-wrap {
  cursor: pointer;

  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
    display: none;
  }
}

.page-xtrading-all-publications__channels-item {
  cursor: pointer;
  padding: 0 16px;

  &:hover {
    background: rgba(133, 133, 133, 0.1);
  }

  .v-input--selection-controls__input {
    &:hover {
      color: transparent;
    }
  }
}
</style>

<script>
import Side from "@/components/Side";
import DialogRemove from "@/components/DialogRemove";
import List from "@/components/List";
import StatusLabel from "@/components/StatusLabel";

export default {
  name: "PageXtradingAllPublications",
  components: {
    Side,
    DialogRemove,
    List,
    StatusLabel,
  },
  data: (_this) => ({
    loading: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showPublish: false,
    showNew: false,
    showEdit: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    methods: [],
    startOfDay: new Date(
      `${new Date().toISOString().substr(0, 10)}T00:00:00.000Z`
    ),
    endOfDay: new Date(
      `${new Date().toISOString().substr(0, 10)}T23:59:59.999Z`
    ),
    feedItems: [],
    // filter settings
    publicationStatusType: undefined,
    channelSubscriptionItems: [],
    categoryItems: [],
    allSelectedItems: false,
    channelsListVisible: false,
    channelItem: null,
    channelItemId: [],
    cloneChannelsItems: [],
  }),
  props: ["update", "select", "item", "countPublication"],

  watch: {
    update() {
      this.loadItems();
    },

    select(items) {
      this.selected = items;
    },

    item() {
      this.loadChannelSubscriptionItems();
    },

    items(itemsNew) {
      if(this.$route.query.edit) {
        const item = itemsNew.find(item => item.id === this.$route.query.edit);
        this.editItem(item);
      }
    },

    channelSubscriptionItems(v) {
      this.cloneChannelsItems = v.map(item => ({...item, checked: false}))
    },
  },
  mounted() {
    this.$queryFilters().get();
    this.loadChannelSubscriptionItems();
    this.loadFeedItems();
    this.searchItems();
  },
  computed: {
    campaignId() {
      return this.$router.currentRoute.params.id || undefined;
    },

    itemId() {
      return this.$router.currentRoute.query.edit || undefined;
    },

    channelTitle () {
      return { fontSize: '.8125rem', fontWeight: '500', lineHeight: '1rem' }
    }
  },
  created() {
    this.loadCountriesItems();
  },
  methods: {
    selectChannel(item, clear = false) {     
      if (clear) {
        this.channelItem = null
        this.channelItemId = []
        this.cloneChannelsItems = this.cloneChannelsItems.map(item => ({...item, checked: false}))
        this.searchItems()
        return
      }

      const index = this.cloneChannelsItems.findIndex(el => el.id === item.id)
      this.cloneChannelsItems[index].checked = !this.cloneChannelsItems[index].checked

      this.channelItem = item
      if (!this.cloneChannelsItems[index].checked) {
        const idx = this.channelItemId.findIndex(el => el.id === item.id)
        this.channelItemId.splice(idx, 1)
        this.searchItems()
        return
      }

      this.channelItemId = [...this.channelItemId, item.id]
      this.searchItems()
    },

    clearFilters () {
      this.$queryFilters().clear();
      this.loadItems();
    },

    loadCountriesItems () {
      const td = ({ name: text, code: value }) => ({ text, value });
      this.$services.api_programmatic.location_country.search()
        .then(response => {
          if (response.data) this.countries = response.data.map(td)
          else this.countries = []
        });
    },

    getImage(item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`;
      return `${url + item.controller_name.toLowerCase()}.png`;
    },

    getReportCount(item) {
      const report = item.job.status_report;
      if (report)
        return Object.keys(report).filter((k) => typeof report[k] === "object")
          .length;
      return 0;
    },

    formatLocation(str) {
      return (str || "").split(",").slice(0, 2).join(",");
    },

    getFeedItem(feed_id, field) {
      for (let i = 0, len = this.feedItems.length; i < len; i++) {
        const c = this.feedItems[i];
        if (c.id === feed_id) return field ? c[field] : c;
      }
      return "?";
    },

    loadFeedItems() {
      this.$services.api_programmatic.feed
        .search({ where: { status: [1] } })
        .then((response) => {
          this.feedItems = response.data;
        });
    },

    loadChannelSubscriptionItems() {
      if (this.item) {
        this.$services.api_programmatic.channel_subscription.search({where: { status: [1], types: this.item.types, methods: this.item.methods, channel: true }})
        .then(response => {
          this.channelSubscriptionItems = response.data
        });
      }
    },

    // Request

    removeItems() {
      this.showEdit = false;
      this.$router.push(`/xtrading/campaign/${this.campaignId}`);
      this.loadItems();
    },

    sortBy (value, order) {
      this.loadItems({order: [[value, order]]});
    },
    
    searchItems(e) {
      if (e && e?.target?.value === '') {
        this.allSelectedItems = false;
      }
     
      this.selected = [];
      this.loading = true;
      // debounce request
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loadItems();
      }, 100);
    },

    loadItems(options = {}) {
      this.loading = true;
      this.$queryFilters().save();
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        mode: "list",
        page: this.page || 1,
        limit: this.limit,
      };

      const query = this.$queryFormat({
        where: {
          status: 1,
          campaign_id: this.campaignId,
          job: {required:true},
          campaign: {required:true},
          channel_subscription: {required:true},
          channel: {required:true},
        },
        options: Object.assign({}, defaultOptions, options),
      })
      this.$emit('childData', {queryPublicationList: query});
      this.$services.api_programmatic.publication.search(query)
        .then(response => {
          if (response.data) {
            this.items = response.data.items;
            this.count = response.data.count;
            this.offset = response.data.offset;
            this.limit = response.data.limit;
          } else {
            this.items = [];
            this.count = 0;
            this.offset = 0;
            this.limit = 0;
          }
          this.updateSelected();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changePage(page) {
      this.page = +page;
      this.searchItems();
    },

    confirmRemoveItems() {
      const td = (v) => v.id;
      const ids = this.selected.map(td);
      this.$services.api_programmatic.publication
        .remove({ where: { id: ids }, options: {limit:ids.length}})
        .then((response) => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 });
          this.selected = [];
          this.showDialogRemove = false;
          this.loadItems();
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 });
        });
    },

    updateSelected() {
      if (this.allSelectedItems) {
        this.selected = this.items;
      }

      const selected = [...this.selected];
      const items = [...this.items];
      for (let i = 0, lenI = selected.length; i < lenI; i++) {
        const a = selected[i];
        for (let j = 0, lenJ = items.length; j < lenJ; j++) {
          const b = items[j];
          if (a.id === b.id) {
            selected[i] = b;
            break;
          }
        }
      }
      this.selected = selected;
    },

    // Mutliple items

    removeSelected() {
      this.showDialogRemove = true;
    },

    editSelected() {
      this.showEdit = true;
      if (this.selected.length > 0) {
        const item = this.selected[0];
        this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/?edit=${item.id}`);
      }
    },

    selectItem(item) {
      for (let i = 0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i, 1);
      }
      return this.selected.push(item);
    },

    selectItems(arr) {
      this.selected = arr;
    },

    closeEdit() {
      this.showEdit = false;
      this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/`);
    },

    closePublish() {
      this.showPublish = false;
      this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/`);
    },

    selectAllPages() {
      this.allSelectedItems = true;
      this.updateSelected();
    },

    // Single item

    updateItems() {
      this.loadItems();
    },

    newItem() {
      this.selected = [];
      this.showNew = true;
    },

    editItem(item) {
      this.selected = [item];
      if (!this.$route.query.edit) {
        this.$router.push(
          `/xtrading/campaign/${this.campaignId}/publications/?edit=${item.id}`
        );
      }
      
      this.showEdit = true;
    },

    editPermissionItem(item) {
      this.selected = [item];
      this.showDialogPermissionEdit = true;
    },

    removeItem(item) {
      this.selected = [item];
      this.showDialogRemove = true;
    },

    applyFilters() {
      this.searchItems();
    },

    resetFilters() {
      this.applyFilters();
    },
  },
};
</script>
